import * as React from 'react';

import Layout from '../components/core/layout';
import Seo from '../components/core/seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <h1>Not Found</h1>
  </Layout>
);

export default NotFoundPage;
